.LoginPage {
    text-align: center;
}

.LeftBanner {
    background-image: url('wedding.jpg');
    background-size: cover;
    background-position: center center;
    width: 40%;
    height: 100%;
}

.LoginFields {
    background: white;
    color: white;
    text-align: left;
    margin: 0;
    height: 100%;
    padding: 80px;

    img {
        width: 220px;
    }

    h1 {
        font-size: 48px;
        font-weight: 700;
        line-height: 65px;
        margin: 0;
    }

    h3 {
        font-size: 32px;
        line-height: 40x;
        margin: 0;
    }

    a {
        width: 100%;
        display: inline-block;
        padding: 20px;
        border-radius: 10px;
        text-align: center;
        color: #2b2a2a;
        font-size: 14px;
        margin-top: 22px;
    }
}
