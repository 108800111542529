.ant-menu-horizontal > .ant-menu-item a,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item span {
    color: var(--menu-link-color);
    text-transform: uppercase;
    font-size: 13px;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
.ant-menu-horizontal > .ant-menu-item a:hover,
.ant-menu-horizontal > .ant-menu-item span:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected span {
    color: var(--menu-link-active);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: solid 2px var(--light-torquise);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    padding: 0 10px;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
    right: 10px;
    left: 10px;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: none;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    font-size: 13px;
}

.ant-menu-horizontal {
    border-bottom: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: var(--ultralight-mint);
    color: var(--light-mint);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:hover,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu:hover .ant-menu-submenu-title,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu:hover .ant-menu-submenu-arrow {
    color: var(--light-mint);
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid var(--light-torquise);
}

.ant-menu-item-selected {
    color: var(--menu-link-active);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    padding: 10px;
}
