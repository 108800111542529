// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@200;300;400;500;600;700&display=swap');

* {
    font-family: 'TTNormsRegular', 'Source Sans 3', sans-serif;
    font-weight: 400;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body {
    font-family: 'TTNormsRegular', 'Source Sans 3', sans-serif;
    font-size: 13px;
    font-weight: normal;
}

p {
    font-size: 13px;
}

h1,
h2,
h3,
h4,
h5,
p {
    color: #333;
}

.text-sm {
    font-size: 0.8rem;
}

h5,
.text-base {
    font-size: 18px;
}

h4,
.text-lg {
    font-size: 20px;
}

h3,
.text-xl {
    font-size: 22px;
}

h2,
.text-2xl {
    font-size: 24px;
}

h1,
.text-3xl {
    font-size: 26px;
}
