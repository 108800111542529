@font-face {
    font-family: TTNormsMedium;
    src: url('fonts/tt-norms-pro-cufonfonts/TT Norms Pro Medium.otf') format('opentype');
}

@font-face {
    font-family: TTNormsRegular;
    src: url('fonts/tt-norms-pro-cufonfonts/TT Norms Pro Regular.otf') format('opentype');
}

@font-face {
    font-family: TTNormsBold;
    src: url('fonts/tt-norms-pro-cufonfonts/TT Norms Pro Bold.otf') format('opentype');
}
