@import 'styles/variables.scss';
@import 'styles/mixins.scss';

@import 'styles/fonts.scss';
@import 'styles/typography.scss';
@import 'styles/buttons.scss';

@import 'styles/ant.overrides.scss';
// @import "antd/dist/antd.css"

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import 'styles/slick-override.scss';

.fetti-border-bottom {
    border-bottom: 1px solid #dfdfdf;
}

.main-header {
    z-index: 9999;
    // box-shadow: 0px 0 4px 4px rgba(0, 0, 0, 0.1);
    // border-bottom: 1px solid #dfdfdf52;
}

.confetti {
    > .particle {
        opacity: 0;
        position: absolute;
        animation: confetti 3s ease-in infinite;
        &.c1 {
            background-color: rgba(76, 175, 80, 0.5);
        }
        &.c2 {
            background-color: rgba(156, 39, 176, 0.5);
        }
    }
}

@keyframes confetti {
    0% {
        opacity: 0;
        transform: translateY(0%) rotate(0deg);
    }
    10% {
        opacity: 1;
    }
    35% {
        transform: translateY(-800%) rotate(270deg);
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(2000%) rotate(1440deg);
    }
}

#confetti {
    overflow-y: hidden;
    overflow-x: hidden;
    width: 100%;
    margin: 0;
    height: 100%;
    position: absolute;
    top: 0;
}
